@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/components.scss';
@import 'styles/typography.scss';

.protecting-block {
    h2 {
        margin-bottom: 24px;
    }

    h5 {
        font-weight: 300;
    }

    a {
        color: $brand4;
        @include responsible-font-size($responsible-h5-font-sizes)
    }

    @include for-medium {
        text-align: center;
    }
}