@import 'styles/colors.scss';

.rating {
    display: flex;
    flex-direction: row;
    align-items: center;

    &--small {
        gap: 2px;
    }

    &--large {
        gap: 10px;
    }

    &__item {
        width: 24px;
        height: 24px;
        color: $gray-400;
        transition: color .3s ease;

        &--active {
            color: $orange;
        }
    }
}