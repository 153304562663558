@import 'styles/colors.scss';
@import 'styles/media.scss';

.reviews-list {
    display: flex;
    flex-direction: column;
    gap: 44px;

    h5 {
        text-align: center;
    }
}