@import 'styles/colors.scss';
@import 'styles/media.scss';

.reviews-statistic-block {
    @mixin defaultGap {
        gap: 80px;

        @include for-large {
            gap: 40px;
        }
    }

    display: flex;
    flex-direction: row;
    @include defaultGap;

    @include for-large {
        flex-direction: column;
    }

    & > div:nth-child(1) {
        display: flex;
        @include defaultGap;

        & > h2 {
            background-color: $brand4;
            color: $white;
            padding: 20px;
            border-radius: 10px;
        }
    }

    &__rating {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
    }

    &__stars {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 16px;

        @include for-small {
            gap: 6px;
        }
    }

    &__stars-wrapper {
        width: 100%;
        display: flex;
        @include defaultGap;

        @include for-small {
            flex-direction: column;
            gap: 6px;
        }
    }
}