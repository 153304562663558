@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/components.scss';
@import 'styles/typography.scss';

.offer-block {
    text-align: center;

    & > h5 {
        margin-top: 44px;
        margin-bottom: 32px;
    }

    &__cards-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 44px;

            @include for-medium {
                flex-direction: column;
            }
    }

    &__card {
        flex: 1 1;
        background-color: $white;
        padding: 44px 24px;
        text-align: start;
        box-shadow: 0 12px 30px 0 rgba(4, 6, 36, 0.11);
        border-radius: 16px;

        & > h5 {
            margin-bottom: 24px;
        }
    }
}