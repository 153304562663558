@import 'styles/colors.scss';
@import 'styles/typography.scss';

.avatar {
    width: 82px;
    height: 82px;
}

.avatar-input {
    & > label {
        & > div {
            display: flex;
            align-items: center;
            gap: 12px;
        }

        input { 
            display: none;
        }

        p {
            font-size: $body-s-font-size;
            color: $gray-400;

            &:nth-child(1) {
                margin-bottom: 4px;
            }
        }
    }
}