@import 'media.scss';

$horizontal-spacing-sizes: (
  null: 20px,
  small: 20px,
  medium: 60px,
  large: 100px
);

$vertical-spacing-sizes: (
  null: 42px,
  small: 42px,
  medium: 60px,
  large: 100px
);

@mixin responsible-horizontal-padding($map: $horizontal-spacing-sizes, $breakpoints: $breakpoints) {
    @include responsible($map, $breakpoints) using ($var) {
        padding-inline: $var;
    }
}

@mixin responsible-vertical-padding($map: $vertical-spacing-sizes, $breakpoints: $breakpoints) {
    @include responsible($map, $breakpoints) using ($var) {
        padding-block: $var;
    }
}