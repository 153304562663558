@import 'styles/colors.scss';
@import 'styles/typography.scss';

.divider {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-block: 24px;

    & > hr {
        width: 100%;
        border-top: 0.5px solid $gray-300;
    }

    & > p {
        color: $gray-400;
        font-size: $body-s-font-size;
    }
}