@import 'styles/components.scss';
@import 'styles/typography.scss';

.review {
    @include card;

    &__header {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        &-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 16px;
            gap: 12px;

            h6 {
                margin-bottom: 8px;
            }

            p {
                color: $gray-500;
            }
        }

        &-actions {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 8px;

            & > label {
                width: auto;
            }

            @include for-medium {
                width: 100%;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }

    & > p {
        margin-block: 24px;
    }

    &__tags {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        & > span {
            padding: 12px 16px;
            border-radius: 100px;
            border: 1px $gray-300 solid;
            font-size: $body-m-font-size;
        }

        &--negative {
            margin-top: 12px;

            & > span {
                border: 1px $orange solid;
            }
        }
    }
}