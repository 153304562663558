@import 'styles/media.scss';
@import 'styles/components.scss';

.leave-review-button {
    @include primary-button;
    min-width: 200px;

    @include for-medium {
        width: 100%;
    }
}