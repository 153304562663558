@import 'styles/colors.scss';
@import 'styles/typography.scss';

.switch {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > p {
        margin-inline: 16px;
        font-size: $body-m-font-size;
    }

    & > div {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 16px;
    
        & > input {
            opacity: 0;
            width: 0;
            height: 0;
    
            &:checked + span {
                background-color: $brand4;
            }
              
            &:focus + span {
                box-shadow: 0 0 1px $brand4;
            }
            
            &:checked + span:before {
                -webkit-transform: translateX(24px);
                -ms-transform: translateX(24px);
                transform: translateX(24px);
                background-color: $violet;
            }
        }
    
        & > span {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $gray-300;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 34px;
    
            &:before {
                position: absolute;
                content: "";
                height: 24px;
                width: 24px;
                left: -4px;
                bottom: -4px;
                background-color: $violet;
                -webkit-transition: .4s;
                transition: .4s;
                border-radius: 50%;
            }
        }
    }
}