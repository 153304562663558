@import 'styles/colors.scss';
@import 'styles/animations.scss';

.creator-links {
    display: flex;
    gap: 16px;

    & > a {
        @include hover-zoom-animation(1.2);
        color: $black;
    }
}