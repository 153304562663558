@import 'styles/media.scss';

.header {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 44px;

    @include for-medium {
        gap: 16px;
    }

    &__search {
        max-width: 670px;
    }
}