@import 'styles/colors.scss';
@import 'styles/media.scss';

.reviews-block {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.reviews-list {
    display: flex;
    flex-direction: column;
    gap: 44px;
}