@use "sass:map";
@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/typography.scss';

@import '../../styles.module.scss';

.menu {
    @include __flex-row;
    flex-wrap: wrap;
    gap: 50px;
    z-index: 10;

    a {
        font-size: $body-l-font-size;
    }

    p {
        cursor: pointer;
    }

    div:nth-child(1) {
        display: flex;
        gap: 40px;
    }

    div:nth-child(2) {
        display: flex;
        gap: 10px;
    }

    @include for-large {
        display: none;
        position: fixed;
        top: 100px;
        left: 0;
        width: 100%;
        flex-direction: column;
        align-items: start;
        background-color: $white;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        padding: 44px 20px;

        div:nth-child(1) {
            flex-direction: column;
        }

        div:nth-child(2) {
            flex-direction: column;
            width: 100%;

            a {
                width: 100%;
            }
        }
    }

    &__link {
        color: $black;

        @include for-large {
            font-weight: bold;
        }

        &--active {
            color: $brand4;
        }
    }

    &--active {
        display: flex;
    }
}