@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/typography.scss';

.verification-wrapper {
    min-height: 80vh;

    @include for-medium {
        padding: 0;
    }
}

.verification {
    color: $black;
    max-width: 500px;
    background-color: $white;
    padding: 44px;
    border-radius: 16px;

    @include for-medium {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        padding-inline: 20px;
    }

    & > h5 {
        margin-bottom: 32px;
    }

    & > button {
        margin-top: 24px;
    }

    & > a {
        margin-top: 24px;
    }

    & > form {
        display: flex;
        flex-direction: column;
        gap: 24px;

        a {
            color: $brand5;
            font-size: $body-s-font-size;
        }
    }

    p {
        font-size: $body-s-font-size;
    }

    button {
        width: 100%;
    }

    &__subtitle {
        color: $gray-400;
    }

    &__block {
        display: flex;
        flex-direction: column;
        gap: 16px;

        button {
            font-size: $body-s-font-size;
        }
    }

    &__link-input {
        display: flex;
        gap: 1px;

        input {
            font-weight: 500;
        }

        & > p {
            font-size: 14px;
            color: $black;
            font-weight: 500;
            margin-top: 1.14em;
        }
    }
}