$s-breakpoint: 480px;
$m-breakpoint: 720px;
$l-breakpoint: 1024px;

$breakpoints: (
  small: $s-breakpoint,
  medium: $m-breakpoint,
  large: $l-breakpoint
);

@mixin for-small {
  @media (max-width: $s-breakpoint) { @content; }
}

@mixin for-between-small-medium {
  @media (min-width: ($s-breakpoint + 1)) and (max-width: $m-breakpoint) { @content; }
}

@mixin for-medium {
  @media (max-width: $m-breakpoint) { @content; }
}

@mixin for-between-medium-large {
  @media (min-width: ($m-breakpoint + 1)) and (max-width: $l-breakpoint) { @content; }
}

@mixin for-large {
  @media (max-width: $l-breakpoint) { @content; }
}

@mixin for-large-and-more {
  @media (min-width: $l-breakpoint) { @content; }
}

@mixin responsible($map, $breakpoints: $breakpoints) {
    @each $breakpoint, $var in $map {
        @if $breakpoint == null {
            @content($var);
        }
        @else {
            @if map-has-key($breakpoints, $breakpoint) {
                $breakpoint: map-get($breakpoints, $breakpoint);
            }
            @media screen and (min-width: $breakpoint) {
                @content($var);
            }
        }
    }
}