@import 'styles/colors.scss';
@import 'styles/typography.scss';

.toggle-button {
    display: inline-block;

    & > input {
        opacity: 0;
        width: 0;
        height: 0;
        display: none;

        &:checked + span {
            border: 1px $brand2 solid;
        }
    }

    & > span {
        padding: 8px 16px;
        border: 1px $gray-300 solid;
        border-radius: 32px;
        display: flex;
        align-items: center;
        gap: 4px;

        transition: background-color .3s ease;

        &:hover {
            background-color: $gray-100;
        }
    }

    p {
        font-size: $body-m-font-size;
    }

    img {
        max-width: 24px;
    }

    svg {
        max-width: 24px;
    }
}