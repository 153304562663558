@import 'styles/colors.scss';
@import 'styles/typography.scss';

.dropdown {
    padding: 16px 46px 16px 26px;
    border-radius: 26px;
    border: 1px $gray-200 solid;
    @include responsible-font-size($responsible-body-font-sizes);
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='orchid' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 16px center;
    background-size: 24px;

    &:focus-visible {
        outline: none;
        border: 1px $brand2 solid;
    }
}