@import 'styles/typography.scss';
@import 'styles/colors.scss';
@import 'styles/media.scss'; 
@import 'styles/animations.scss';

.creator-card {
    $card-p-font-sizes: (
        null: $body-s-font-size,
        small: $body-s-font-size,
        medium: $body-m-font-size,
        large: $body-m-font-size,
    );

    @include hover-shadow-animation;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-height: 580px;
    max-width: 400px;
    padding: 20px;
    border-radius: 6px;
    border: solid 1px $gray-200;
    background-color: $white;
    text-align: start;
    color: $black;

    p {
        color: $gray-700;
        @include responsible-font-size($card-p-font-sizes);
    }

    & > div > p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__image {
        width: 100%;
        aspect-ratio: 1;
        border-radius: 6px;
        object-fit: cover;
    }

    &__image-wrapper {
        width: 100%;
        position: relative;
        margin-bottom: 12px;

        & > span {
            position: absolute;
            top: 16px;
            right: 16px;
            min-width: 50px;
            background-color: $white;
            color: $black;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 55px;

            & > svg {
                color: $orange;
                margin-right: 2px;
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        & > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        p {
            margin-bottom: 6px;
        }

        h5 {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__stats {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;

        svg {
            color: $brand2;
        }

        h6 > b {
            text-decoration: line-through;
            color: $gray-500;
            font-weight: lighter;
        }
        
        span {
            padding: 4px;
            background-color: rgba(182, 244, 144, 0.2);
            border-radius: 4px;

            p {
                color: $darkGreen;
            }
        }

        & > div {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
}