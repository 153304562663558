.reviews {
    h3 {
        text-align: center;
        margin-bottom: 44px;
    }

    h5 {
        text-align: center;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 44px;
    }
}