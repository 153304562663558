@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/components.scss';
@import 'styles/typography.scss';
@import 'styles/animations.scss';

.browse-by-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

    @include for-medium {
        flex-direction: column;
    }

    & > div {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        @include for-medium {
            flex-direction: column;
        }

        & > a {
            @include button;
            @include hover-shadow-animation;
            border-radius: 4px;
            color: $black;
            
            display: flex;
    
            & > img {
                margin-right: 12px;
            }
        }
    }
}