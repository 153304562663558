@import 'styles/typography.scss';
@import 'styles/colors.scss';

.price {
    display: flex;
    align-items: center;
    gap: 8px;

    h6 > b {
        text-decoration: line-through;
        color: $gray-500;
        font-weight: lighter;
    }
    
    span {
        padding: 4px;
        background-color: rgba(182, 244, 144, 0.2);
        border-radius: 4px;

        p {
            color: $darkGreen;
        }
    }
}