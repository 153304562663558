@import 'styles/media.scss';
@import 'styles/components.scss';

.profile-settings {
    margin-bottom: 42px;

    & > h3 {
        margin-bottom: 24px;
    }
 
    & > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 24px;

        @include for-medium {
            flex-direction: column;

            button {
                width: 100%;
            }
        }

        & > form {
            flex: 1;

            @media (min-width: ($m-breakpoint + 1)) {
                @include card;
            }

            & > div {
                margin-bottom: 16px;
            }
        }
    }
}