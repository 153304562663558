@import 'styles/colors.scss';
@import 'styles/typography.scss';


.text-area {
    & > label {
        color: $gray-400;
        font-size: $body-s-font-size;
        margin-bottom: 8px;
    }

    & > textarea {
        resize: none;
        border: none;
        border-bottom: 1px $gray-400 solid;
        min-height: 100px;
        max-height: 600px;
        height: auto;
        width: 100%;
        padding-bottom: 12px;
        line-height: 1.5;
        caret-color: $brand2;
        outline: none;

        &:focus {
            border-bottom: 1px $brand4 solid ;
        }

        &::placeholder {
            color: $gray-400;
            font-size: $body-s-font-size;
            font-weight: lighter;
        }
    }
}