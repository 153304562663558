@import 'styles/colors.scss';
@import 'styles/media.scss';

@mixin accountCard {
    padding: 12px;
    background-color: $gray-100;
    border-radius: 12px;
}

.account {
    display: flex;
    gap: 32px;

    & > span:nth-child(1) {
        flex: 1;
    }

    & > span:nth-child(2) {
        flex: 2;
    }

    @include for-medium {
        flex-direction: column;
    }
}