@use "sass:map";
@import 'styles/colors.scss';
@import 'styles/spacing.scss';
@import 'styles/typography.scss';

@mixin __flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.header-wrapper {
    padding-block: 20px;
}

.header {
    @include __flex-row;
    color: $black;

    &__burger-icon {
        display: none;

        @include for-large {
            display: block;
        }
    }
}