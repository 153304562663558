@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/components.scss';
@import 'styles/typography.scss';

.between-block {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 80px;

    @include for-large {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    img {
        width: 100%;
        max-width: 612px;
    }

    &__content {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 24px;

        h5 {
            font-weight: 300;
        }
    }   
}