@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/components.scss';
@import 'styles/typography.scss';

.creator-block {
    display: flex;
    justify-content: space-between;
    gap: 80px;

    @include for-large {
        flex-direction: column-reverse;
        align-items: center;
    }

    & > img {
        width: 50%;
        max-width: 1100px;
        min-width: 375px;
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 44px;

        @include for-large {
            align-items: center;
            text-align: center;
        }

        h5 {
            font-weight: 300;
        }

        a {
            @include primary-button;
        }
    }
}