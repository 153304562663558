@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/typography.scss';

.sign-in-up {
    &__form {
        a {
            font-size: $body-m-font-size;
            text-decoration: underline;
        }

        & > h5 {
            margin-bottom: 16px;
        }

        & > p {
            color: $gray-600;
        }

        & > form {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 24px;
            margin-top: 32px;

            & > a {
                text-align: right;
            }
        }
    }

    &__third-party-services {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > button {
            border-radius: 12px;
            border-color: $gray-200;
        }
    }

    &__policy-text {
        color: $gray-400;
        font-size: 12px;
        margin-top: 32px;

        & > a {
            font-size: 12px;
        }
    }
}