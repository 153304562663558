@import 'styles/media.scss';
@import 'styles/colors.scss';
@import 'styles/components.scss';

@import '../../styles.module.scss';

.right-card {
    @media (min-width: ($m-breakpoint + 1)) {
        @include card;
        padding: 20px;
        flex: 2.2;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;

    & > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        gap: 24px;

        & > p {
            @include accountCard;
            color: $gray-700;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 24px;
        width: 100%;

        button, a {
            border-radius: 16px;
        }

        & > div:nth-child(2) {
            display: flex;
            gap: 10px;

            @include for-medium {
                flex-direction: column;
                width: 100%;
            }
        }

        & > button {
            @include for-medium {
                width: 100%;
            }
        }

        @include for-medium {
            flex-direction: column-reverse;
        }
    }

    &__user-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;

        @include for-medium {
            justify-content: center;
        }

        & > span {
            padding: 12px 16px;
            border: 1px $gray-300 solid;
            border-radius: 100px;
        }

        & > button {
            padding: 12px 16px;
            border: 1px $brand2 solid;
            border-radius: 100px;
            color: $brand2;
            background: none;
        }
    }

    &__stats {
        @include for-medium {
            display: none;
        }
    }
}