.favorites {
    h3 {
        text-align: center;
        margin-bottom: 44px;
    }

    h5 {
        text-align: center;
    }

    &__list {
        display: grid;
        gap: 32px;
        grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));

        &-item {
            max-width: 100%;
        }
    }
}