@import 'styles/media.scss';
@import 'styles/colors.scss';
@import 'styles/components.scss';

@import '../../styles.module.scss';

.stats {
    max-width: 70%;
    
    @include for-large {
        max-width: 100%;
    }

    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: 12px;
    justify-items: center;

    @include accountCard;
}