@import 'styles/media.scss';

.error-page {
    width: 100%;
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    & > div {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}