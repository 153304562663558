@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/components.scss';
@import 'styles/typography.scss';

.forgot-password {
    & > h5 {
        margin-bottom: 16px;
    }

    & > p {
        font-size: $body-s-font-size;
        color: $gray-600;
        margin-bottom: 32px;
    }

    form {
        width: 100%;

        & > div {
            margin-bottom: 24px;
        }

        & > button {
            width: 100%;
            margin-bottom: 12px;
        }
    }

    & > a {
        width: 100%;
    }
}