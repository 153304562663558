@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/components.scss';
@import 'styles/typography.scss';
@import 'styles/animations.scss';

.explore-block {
    color: $white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__primary-info {
        max-width: 690px;

        @include for-large {
            max-width: 100%;
        }

        & > h1 {
            margin-bottom: 24px;

            & > span {
                @include main_text_gradient;
            }
        }

        & > h5 {
            margin-bottom: 44px;
        }
    }

    &__trending {
        margin-top: 36px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 12px;

        & > p {
            font-size: $body-m-font-size;
            font-weight: bold;
        }

        & > a {
            @include button;
            @include hover-zoom-animation(1.05);
            color: $white;
            border-color: $white;
            font-size: $body-m-font-size;
            

            @include for-medium {
                padding-inline: 12px;
                padding-block: 8px;
            }
        }
    }

    &__secondary-info {
        position: relative;
        margin-block: 100px;
        margin-left: 24px;

        width: 100%;
        max-width: 480px;
        aspect-ratio: 1;
        object-fit: cover;
        background-image: url('../../../../assets/home-main-image.png');
        background-repeat: no-repeat;

        @include for-large {
            display: none;
        }
    }

    &__three-white-lines {
        width: 36%;
        position: absolute;
        top: -30%;
        left: -36%;
    }

    &__blue-star {
        width: 30%;
        position: absolute;
        top: -14%;
        right: -5%;
    }

    // &__main-image {
    //     width: 100%;
    //     max-width: 480px;
    //     aspect-ratio: 1/1;
    //     border-top-left-radius: 50px;
    //     object-fit: cover;
    // }

    &__review {
        position: absolute;
        bottom: -20%;
        right: -12%;
        width: 80%;
        padding: 20px;

        @media (max-width: 1300px) {
            h6 {
                font-size: 12px;
            }

            p {
                font-size: 12px;
            }
        }
    }
}