@import './media.scss';
@import './spacing.scss';
@import './colors.scss';
@import './typography.scss';
@import './components.scss';

:global #root {
  position: relative;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "Urbanist", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', sans-serif;
  font-optical-sizing: auto;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

input {
  font-family: "Urbanist", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

h1 {
  @include responsible-font-size($responsible-h1-font-sizes);
  font-weight: bold;
  line-height: 1.31;
}

h2 {
  @include responsible-font-size($responsible-h2-font-sizes);
  font-weight: bold;
  line-height: 1.2;
}

h3 {
  @include responsible-font-size($responsible-h3-font-sizes);
  font-weight: bold;
  line-height: 1.91;
}

h4 {
  @include responsible-font-size($responsible-h4-font-sizes);
  font-weight: bold;
  line-height: 1.5;
}

h5 {
  @include responsible-font-size($responsible-h5-font-sizes);
  font-weight: 600;
}

h6 {
  @include responsible-font-size($responsible-h6-font-sizes);
  font-weight: 600;
}

p, ul, li, a {
  @include responsible-font-size($responsible-body-font-sizes);
  font-weight: 300;
}

a {
  color: $brand4;
  text-decoration: none;
}

.block {
  @include block;
}

.button {
  &--primary {
    @include primary-button;
  }

  &--primary-light {
    @include primary-button-light;
  }

  &--secondary {
    @include secondary-button;
  }

  &--secondary-light {
    @include secondary-button-light;
  }
}

.card {
  @include card;
}

.wrapper {
  @include block;
  background-color: $white;
  color: $black;

  & > * {
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
  }

  &--gray-bg {
    background-color: $gray-100;
  }

  &--black-bg {
    background-color: $black;
    color: $white;
  }

  &--with-spots {
    @include background-spots;
  }

  &--with-gradient {
    @include background-gradient;
    color: $white;
  }
}

.error-text {
  @include error-text; 
}