@use "sass:map";

$black: #000000;
$white: #ffffff;
$lightGreen: #b6f490;
$darkGreen: #7fab65;
$violet: #8b69ef;
$orange: #ff9736;
$brand1: #000000;
$brand2: #fd35cb;
$brand3: #f59a6d;
$brand4: #d231f1;
$brand5: #6094e0;
$gray-0: #ffffff;
$gray-25: #fcfcfd;
$gray-50: #f9fafb;
$gray-100: #f2f4f7;
$gray-200: #eaecf0;
$gray-300: #d0d5dd;
$gray-400: #98a2b3;
$gray-500: #667085;
$gray-600: #475467;
$gray-700: #344054;
$gray-800: #1d2939;
$gray-900: #1d2939;
$gray-1000: #000000;