@import "styles/media.scss";
@import "styles/colors.scss";
@import "styles/spacing.scss";
@import "styles/typography.scss";

$footer-vertical-padding: (
  null: 42px,
  small: 42px,
  medium: 50px,
  large: 50px
);

.footer-wrapper {
    @include responsible($footer-vertical-padding) using ($var) {
        padding-block: $var;
    }
}

.footer {
    color: $white;

    &__link {
        color: $white;

        &--active {
            color: $brand4;
        }
    }

    hr {
        margin-block: 24px;
    }

    section {
        display: flex;
        flex-direction: row;
        align-items: self-start;
        justify-content: space-between;

        div {
            display: flex;
            flex-direction: row;

            a:nth-child(2) {
                padding-inline: 40px;
            }
        }
    }

    @include for-small {
        section {
            flex-direction: column;
            align-items: center;
    
            img {
                margin-bottom: 24px;
            }
        }

        p {
            text-align: center;
        }
    }
}