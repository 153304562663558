@import 'media.scss'; 

$h1-font-size: 64px;
$h2-font-size: 56px;
$h3-font-size: 44px;
$h4-font-size: 32px;
$h5-font-size: 23px;
$h6-font-size: 18px;
$body-l-font-size: 18px;
$body-m-font-size: 16px;
$body-s-font-size: 14px;
$label-font-size: 12px;

$responsible-h1-font-sizes: (
  null: $h4-font-size,
  small: $h3-font-size,
  medium: $h2-font-size,
  large: $h1-font-size
);

$responsible-h2-font-sizes: (
  null: $h4-font-size,
  small: $h4-font-size,
  medium: $h3-font-size,
  large: $h2-font-size
);

$responsible-h3-font-sizes: (
  null: $h5-font-size,
  small: $h5-font-size,
  medium: $h4-font-size,
  large: $h3-font-size
);

$responsible-h4-font-sizes: (
  null: $h6-font-size,
  small: $h6-font-size,
  medium: $h5-font-size,
  large: $h4-font-size
);

$responsible-h5-font-sizes: (
  null: $body-m-font-size,
  small: $body-m-font-size,
  medium: $h6-font-size,
  large: $h5-font-size
);

$responsible-h6-font-sizes: (
  null: $body-m-font-size,
  small: $body-m-font-size,
  medium: $body-m-font-size,
  large: $h6-font-size
);

$responsible-body-font-sizes: (
  null: $body-s-font-size,
  small: $body-s-font-size,
  medium: $body-m-font-size,
  large: $body-l-font-size,
);

@mixin main_text_gradient {
  background: -webkit-linear-gradient(120deg, #ff15e7, #f59a6d, #ff15e7, #af46fb, #6094e0);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin responsible-font-size($map, $breakpoints: $breakpoints) {
    @include responsible($map, $breakpoints) using ($var) {
        font-size: $var;
    }
}