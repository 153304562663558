@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/typography.scss';
@import 'styles/components.scss';

.filter-button {
    @include secondary-button;

    display: none;
    background-color: $white;
    justify-content: space-between;
    border-radius: 12px;
    padding: 16px 44px;

    @include for-medium {
        display: flex;
    }

    & > p {
        font-size: $body-m-font-size;
        font-weight: bold;
    }
}

.mobile-filters {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $white;
    z-index: 10;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;

        & > h5 {
            font-size: $h5-font-size;
        }
    }

    &__body {
        padding-inline: 20px;
        padding-bottom: 100px;
        padding-top: 16px;
        max-height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}