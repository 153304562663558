@import 'styles/colors.scss';
@import 'styles/typography.scss';

.text-input {
    position: relative;

    & > label {
        color: $gray-400;
        font-size: $body-s-font-size;
        margin-bottom: 4px;
        display:inline-block;
    }

    &--underlined {
        & > input {
            border: none !important;
            border-radius: 0px !important;
            border-bottom: 1px $gray-200 solid !important;
            padding: 16px 16px 16px 0px !important;

            &:focus {
                border-bottom: 1px $brand4 solid !important;
            }
        }

        svg {
            top: 12px !important;
        }
    }

    & > input {
        width: 100%;
        padding: 16px 16px 16px 32px;
        border-radius: 16px;
        border: 1px $gray-200 solid;
        caret-color: $brand2;
        outline: none;
        font-size: $body-s-font-size;

        &:focus {
            border: 1px $brand4 solid ;
        }

        &::placeholder {
            color: $gray-400;
            font-size: $body-s-font-size;
            font-weight: 300;
        }

        &[type="password"] {
            -webkit-text-stroke-width: 1.6px;
            letter-spacing: 3px;

            &::placeholder {
                letter-spacing: normal;
                -webkit-text-stroke-width: 0.15px;
            }
        }
    }

    svg {
        position: absolute;
        right: 16px;
        top: 35px;
        width: 24px;
        height: 24px;
        z-index: 1;
        color: $gray-500;
    }
}