@mixin hover-zoom-animation($scale: 1.1) {
    transition: transform .2s ease-in-out;

    &:hover {
        transform: scale($scale);
    }
}

@mixin hover-shadow-animation {
    transition: box-shadow .2s ease-in-out;

    &:hover {
        box-shadow: 0 0 11px rgba(33,33,33,.2); 
    }
}