@import 'styles/media.scss';

.content {
    display: flex;
    justify-content: space-between;
    gap: 32px;

    @include for-medium {
        flex-direction: column;
    }

    &__filters {
        @include for-medium {
            display: none;
        }
    }
}