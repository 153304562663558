@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/components.scss';
@import 'styles/typography.scss';

.title-with-categories {
    &__title {
        width: 100%;
        display: flex;
        gap: 16px;
        margin-bottom: 44px;

        @include for-medium {
            justify-content: center;
        }
    }

    &__content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;

        @include for-medium {
            justify-content: center;
        }
    }
}