@import 'styles/components.scss';
@import 'styles/typography.scss';

.reply-button {
    font-size: $body-s-font-size;
    padding-block: 12px;
}

.reply-dialog {
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: $black, $alpha: .3);

    @include for-medium {
        align-items: flex-start;
    }

    h5 {
        font-size: $h5-font-size;
    }

    & > form {
        display: flex;
        flex-direction: column;
        gap: 32px;
        width: 100%;
        padding: 44px;
        max-width: 700px;
        border-radius: 16px;
        background-color: $white;
        min-height: 400px;
        height: 80%;
        overflow-x: hidden;
        overflow-y: scroll;

        @include for-medium {
            border-radius: 0;
            padding: 20px;
            padding-block: 44px;
            height: 100%;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.reactions {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 32px;
    margin-left: 32px;
}

.reaction {
    display: flex;
    flex-direction: column;

    & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        margin-bottom: 12px;

        h6 {
            margin-bottom: 8px;
        }

        p {
            color: $gray-500;
        }
    }
}