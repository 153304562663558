@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/components.scss';
@import 'styles/typography.scss';

.categories-block {
    @include for-medium {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 44px;

        & > div {
            display: flex;
            align-items: center;
        }

        a {
            @include primary-button;
            margin-left: 24px;
    
            @include for-medium {
                display: none;
            }
        }
    }

    &__content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;

        @include for-medium {
            justify-content: center;
        }
    }

    & > a {
        @include primary-button;
        display: none;

        @include for-medium {
            display: flex;
            margin-top: 24px;
        }
    }
}