@import 'styles/colors.scss';
@import 'styles/spacing.scss';
@import 'styles/components.scss';

.top-creators-block {
    @include responsible-vertical-padding;
    padding-inline: none;
    background-color: $white;

    & > h3 {
        margin-bottom: 44px;
        text-align: center;
    }

    & > h5 {
        text-align: center;
    }

    &--gray-bg {
        background-color: $gray-100;
    }
}