@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/components.scss';
@import 'styles/typography.scss';

.for-creators {
    display: flex;
    justify-content: space-between;
    gap: 80px;

    @include for-large {
        flex-direction: column;
        align-items: center;
    }

    img {
        max-width: 612px;
        width: 100%;
    }

    &__content {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 32px;

        h5 {
            font-weight: 300;
        }
    }  
    
    &__buttons {
        display: flex;
        gap: 16px;

        @include for-medium {
            flex-direction: column;
        }
    }
}