@import 'styles/components.scss';
@import 'styles/media.scss';

.slider {
    @include for-medium {
        padding-inline: 20px;
    }

    :global {
        .slick-slider {
            margin-bottom: 40px;
        }

        .slick-track {
            display: flex !important;
        }

        .slick-slide {
            height: inherit !important;

            & > div {
                height: 95%;
                margin-inline: 16px;
                margin-bottom: 40px;

                & > * {
                    max-width: 100%;
                }
            }
        }
      
        .slick-list {
            margin-inline: -16px;
            margin-bottom: -40px;
        }

        .slick-dots {
            bottom: -25px;

            @include for-medium {
                bottom: -10px;
            }

            & > li > button:before {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                background-color: $black;
                border-radius: 100%;

                @include for-medium {
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }
}