@import 'styles/media.scss';

.creators {
    flex: 2;

    h5 {
        text-align: center;
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        column-gap: 32px;
        row-gap: 32px;

        @include for-between-medium-large {
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        }

        @include for-between-small-medium {
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        }

        // @include for-small {
        //     grid-template-columns: 1fr;
        // }
    }
}