@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/typography.scss';

.wrapper {
    @include for-medium {
        padding: 0;
    }
}

.auth-layout{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 80px;

    &__info {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 24px;

        @include for-medium {
            display: none;
        }

        & > div:nth-child(1) {
            display: flex;
            align-items: center;
            gap: 16px;

            img {
                width: 80px;
            }
        }

        & > h3 {
            line-height: 1.5;
        }

        & > h5 {
            font-weight: 300;
            line-height: 1.25;
        }
    }

    &__form {
        flex: 1;
        padding: 44px;
        background-color: $white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: $black;

        @include for-medium {
            padding-inline: 20px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            margin-bottom: 44px;
        }
    }
}