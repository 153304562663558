@import 'styles/colors.scss';
@import 'styles/media.scss';

.reviews-header-block {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 16px;

    @include for-medium {
        flex-direction: column;
        gap: 24px;
    }

    & > div:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
}