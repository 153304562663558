@import 'styles/media.scss';
@import 'styles/colors.scss';
@import 'styles/components.scss';
@import 'styles/typography.scss';

@import '../../styles.module.scss';

.left-card {
    @media (min-width: ($m-breakpoint + 1)) {
        @include card;
        padding: 20px;
        flex: 1;
    }
    
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 486px;
    height: fit-content;

    @include for-medium {
        max-width: 100%;
    }

    &__image {
        width: 100%;
        aspect-ratio: 1;
        border-radius: 6px;
        object-fit: cover;

        @include for-large-and-more {
            transition: all .2s ease-in-out;

            &:hover {
                transform: scale(1.3);
                box-shadow: 0 12px 24px 0 rgba(33,33,33,.2);
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        h5 {
            margin-top: 8px;
        }

        & > span {
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: $body-m-font-size;
        }
    }

    & > p {
        @include accountCard;
    }

    &__stats {
        display: none;

        @include for-medium {
            display: grid;
        }
    } 
}