@import './media.scss';
@import './colors.scss';
@import './spacing.scss';
@import './typography.scss';

$button-horizontal-padding: (
  null: 36px,
  small: 36px,
  medium: 36px,
  large: 44px
);

$button-vertical-padding: (
  null: 12px,
  small: 12px,
  medium: 12px,
  large: 16px
);

$responsible-button-font-sizes: (
  null: $body-s-font-size,
  small: $body-s-font-size,
  medium: $body-m-font-size,
  large: $body-m-font-size,
);

@mixin button {
  @include responsible($button-horizontal-padding) using ($var) {
    padding-inline: $var;
  }

  @include responsible($button-vertical-padding) using ($var) {
    padding-block: $var;
  }

  @include responsible-font-size($responsible-button-font-sizes);
  
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: $black 1px solid;
  border-radius: 60px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  font-weight: 300;

  & > img {
    max-width: 24px;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

@mixin primary-button {
  @include button;
  background-color: $black;
  color: $white;

  transition: background-color .3s ease;

  &:hover {
    background-color: $gray-900;
  }

  &:disabled {
    background-color: $gray-500;
    border-color: $gray-200;
    color: $gray-200;
  }
}

@mixin primary-button-light {
  @include button;
  background-color: $white;
  color: $black;

  transition: background-color .3s ease;

  &:hover {
    background-color: $gray-100;
  }

  &:disabled {
    background-color: $gray-200;
    border-color: $gray-500;
    color: $gray-500;
  }
}

@mixin secondary-button {
  @include button;
  background: none;
  color: $black;

  transition: background-color .3s ease;

  &:hover {
    background-color: $gray-100;
  }

  &:disabled {
    color: $gray-500;
    border-color: $gray-500;
  }
}

@mixin secondary-button-light {
  @include secondary-button;
  border: $white 1px solid;
  color: $white;

  &:disabled {
    color: $gray-400;
    border-color: $gray-400;
  }
}

@mixin block {
  @include responsible-vertical-padding;
  @include responsible-horizontal-padding;
}

@mixin background-spots {
  overflow: hidden;
  position: relative;
  z-index: 1;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; 
    background-image: url("../assets/bg-spots.svg");
    z-index: -1;

    @include for-medium {
      display: none;
    }
  }
}

@mixin background-gradient {
  background-image: linear-gradient(to right, #e99e75 0%, #e94ec6 21%, #c149e9 49%, #6b95da 88%);
}

@mixin error-text {
  color: $brand2;
  font-size: 12px;
  margin-top: 4px;
}

@mixin card {
  padding-inline: 24px;
  padding-block: 44px;
  border-radius: 16px;
  box-shadow: 0 12px 30px 0 rgba(4, 6, 36, 0.11);
  background-color: $white;
  color: $black;
}