@import 'styles/media.scss';
@import 'styles/colors.scss';
@import 'styles/components.scss';
@import 'styles/typography.scss';

.search-wrapper {
    $border-radius: 32px;

    width: 100%;
    background-color: $white;
    border-radius: $border-radius;
    display: flex;
    flex-direction: row;
    align-items: center;

    & input[type=search] {
        background-color: $white;
        width: 100%;
        padding: 24px 12px 24px 24px;
        border-radius: $border-radius;
        border: none;
        margin-right: 12px;
        font-size: $body-l-font-size;
        outline: none;

        &::placeholder {
            color: $gray-400;
            font-size: $body-l-font-size;
        }

        @include for-small {
            font-size: $body-s-font-size;

            &::placeholder {
                font-size: $body-s-font-size;
            }
        }
    }

    & > label {
        font-size: $body-m-font-size;
        color: $gray-700;
    }

    & > button {
        @include primary-button;

        margin-inline: 16px;

        @include for-small {
            padding: 8px;
        }
    }
}