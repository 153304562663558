@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/components.scss';
@import 'styles/typography.scss';

.browse-block {
    position: relative;

    h1 {
        color: $white;
        margin-bottom: 44px;
    }

    & > form {
        max-width: 670px;
    }

    & > img {
        position: absolute;
        right: 0;
        top: 0;

        @include for-medium {
            display: none;
        }
    }
}