@import 'styles/colors.scss';

.star {
    display: grid;
    grid-template-columns: 1fr 1fr 12fr 3fr;
    align-items: center;
    text-align: center;
    gap: 8px;

    & > span {
        width: 100%;
        height: 4px;
        border-radius: 4px;
        background-color: $gray-200;

        & > span {
            display: block;
            width: 70%;
            height: 4px;
            border-radius: 4px;
            background-color: $brand4;
        }
    }
}