@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/components.scss';
@import 'styles/typography.scss';

.reset-password {
    h5 {
        margin-bottom: 32px;
    }

    form {
        width: 100%;

        & > div {
            margin-bottom: 24px;
        }
    }

    button {
        width: 100%;
        margin-bottom: 12px;
    }
}