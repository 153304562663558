@import 'styles/colors.scss';

.image-error {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $brand5;

    & > svg {
        color: $white;
    }
}

.image {
    background-color: $brand5;
}