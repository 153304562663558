@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/typography.scss';
@import 'styles/components.scss';

.filters {
    flex: 1;
    @include for-medium {
        margin-bottom: 100px;
    }

    &__card {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 24px;

        @media (min-width: $m-breakpoint) {
            @include card;
        }

        & > p {
            font-size: $body-m-font-size;
        }
    }

    &__links {
        display: flex;
        flex-wrap: wrap;
        gap: 12px
    }
}