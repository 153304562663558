@use "sass:map";
@import 'styles/colors.scss';
@import 'styles/spacing.scss';
@import 'styles/media.scss';

.avatar {
    @include for-large {
        display: none !important;
    }

    &__menu {
        display: none;
        flex-direction: column;
        gap: 24px;
        padding: 44px;
        border-radius: 16px;
        background-color: $white;
        position: fixed;
        top: 120px;
        border: 1px $black solid;
        right: map.get($horizontal-spacing-sizes, large);

        & > p {
            cursor: pointer;
        }

        &--active {
            display: flex;
        }
    }
}