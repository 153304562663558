@import 'styles/typography.scss';
@import 'styles/components.scss';
@import 'styles/colors.scss';
@import 'styles/animations.scss';

.category-tag {
    @include button;
    @include hover-shadow-animation;

    box-shadow: 0 12px 30px 0 rgba(4, 6, 36, 0.11);
    background-color: $white;
    border: none;
    padding: 32px;

    @include for-medium {
        padding: 16px;
    }

    &--with-emoji {
        padding: 8px 32px 8px 8px;

        @include for-medium {
            padding: 8px 16px 8px 8px;
        }
    }

    & > span {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(38, 105, 243, 0.2);
        border-radius: 100%;
        width: 48px;
        height: 48px;
        margin-right: 12px;
        font-size: 26px;

        @include for-medium {
            width: 32px;
            height: 32px;
        }
    }

    & > p {
        font-size: $body-m-font-size;
        text-align: center;
        font-weight: bold;
        color: $black;

        @include for-medium {
            font-size: $body-s-font-size;
        }
    }
}