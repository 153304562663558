@import 'styles/typography.scss';
@import 'styles/colors.scss';
@import 'styles/components.scss';

.paginate {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
    margin-top: 32px;
    padding: 0;

    &__page-item {
        @include for-small {
            display: none;
        }
    }

    &__break-item {
        @include for-small {
            display: none;
        }
    }

    &--disabled {
        a {        
            border-color: $gray-300 !important;
            color: $gray-300 !important;
            cursor: not-allowed !important;
        }
    }

    &--active {
        a {
            border-color: $brand2 !important;
            color: $brand2 !important;
        }
    }

    li {
        list-style: none;
    }

    a {
        @include responsible-font-size($responsible-body-font-sizes);
        @include secondary-button;
        border-radius: 6px;
        padding-block: 8px !important;
        padding-inline: 12px !important;
    }
}